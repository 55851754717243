import {Injectable, OnInit} from '@angular/core';
import {
  buildSearchEngine,
  SearchEngine,
  getOrganizationEndpoints,
  PlatformEnvironment,SearchConfigurationOptions
} from '@coveo/headless';
import { ResultsListDataService } from './results-list-data.service';
import { CookieService } from 'ngx-cookie-service';
import { CryptoService } from './crypto.service';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { ENVIRONMENT } from 'src/environments/environment.development';
import { CookieServiceWrapper } from './cookie-service-wrapper.service';

@Injectable()
export class EngineService implements OnInit{
  private engine!: SearchEngine;
  // private st:any;
  private oid:any;

  public constructor(private resultsListDataService: ResultsListDataService, private cookieService:CookieServiceWrapper, private cryptoService:CryptoService, private authService:AuthService,  private router: Router) {
    
    var searchToken = this.getSearchToken();
    this.oid = this.cookieService.get('oid');

   // alert("searchToken-->"+searchToken.length);
    if(searchToken === null || searchToken === undefined){
      this.router.navigate(['/auth']);
    }

    try{
    var searchConfigurationOptions: SearchConfigurationOptions = {
      pipeline:'AdvancedSearchDefault',
      searchHub:'AdvanceSearch',
      preprocessSearchResponseMiddleware: (response:any) => {
           // console.log("preprocessSearchResponseMiddleware called response length", response.body?.totalCount);
           // console.log("searchUid", response.body?.searchUid);
           // console.log("response", response.body);
           resultsListDataService.setTotalCount(response.body?.totalCount);

          //  resultsListDataService.setSearchUID(response.body?.searchUid);
           localStorage.setItem('searchID',response.body?.searchUid);

        return response;
      }
    }
    this.engine = buildSearchEngine({
      // preprocessRequest: (request, clientOrigin) => {

      //   // request.facets

      //     const req = request.body?.toString();
      //     alert(JSON.stringify(req));
      //     return request;
      // },
      configuration: {
        preprocessRequest: (request:any, clientOrigin:any) => {

          // request.facets
          // console.log("clientOrigin", clientOrigin);

          if(clientOrigin == "searchApiFetch" && this.oid){
                try{
                 let req = JSON.parse(request.body); 
                 // console.log("req body", req.q);
                 this.cookieService.set('searchTerm',req.q, 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
                }catch(error){
                   this.router.navigate(['/auth']);
                }
                
                
                this.resultsListDataService.setRequestBody(request.body);
          }

            const req = request.body?.toString();
            //alert(JSON.stringify(req));
            //console.log("req", JSON.stringify(req))
            return request;
        },

     
    
        organizationEndpoints: getOrganizationEndpoints(
          'merckholdingsllcnonproduction1xzti28zp'
        //  this.oid
        ),

        // SIT
        // organizationEndpoints: getOrganizationEndpoints(
        //   'merckholdingsllcnonproduction2x7d3y0p7'
        // ),

        search:searchConfigurationOptions,
        //  organizationId: this.oid,
         organizationId: 'merckholdingsllcnonproduction1xzti28zp',
         accessToken: 'xx24b1e8e0-1dad-4359-a72a-44418b8bbd6c',
        // accessToken: searchToken,

        // SIT
        // accessToken: 'xx0015f1a1-90c1-4e12-a99c-1700e7b6b5e3',
      },
    })

    }catch(err){
       this.authService.checkAuthetication();
    }
  }

  getSearchToken(){
    let st =  this.cookieService.get('st');
    // if(!st){
    //   this.authService.checkAuthetication();
    // }
    // console.log("SEARCH TOKEN ---> "+st);
    return st;
  }


  ngOnInit(): void {
    // // console.log("INSIDE ENGINE ST-->"+this.st);
    // alert("INSIDE ENGINE ST-->"+this.st);
    // if(!this.st){
      // this.authService.checkAuthetication();
    // }
  }

  init(): void{
    // console.log('End init: ');
  } 


  // public async getEngibeObj() {
  //   return this.engine;
  // }

  public getEngibeObj() {
    return this.engine;
  }

}
